import React, { Suspense, useEffect, useState, lazy } from "react";

// Libraries
import { Routes, Route, useLocation } from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";

// Context
import GlobalContext from "./Context/Context";

// Components
import ScrollToTopButton from "./Components/ScrollToTop";
import { PrivateRoute } from "./Components/PrivateRoute";

import { getUserInfo, useUser } from "./Features/User";
import { fetchErrorsList } from "./Features/Errors";

// Pages
const Home = lazy(() => import("@/Pages/Home"));
const NotFound = lazy(() => import("@/Pages/404"));
const Mission = lazy(() => import("@/Pages/Mission"));
const Services = lazy(() => import("@/Pages/Services"));
const Contacts = lazy(() => import("@/Pages/Contacts"));
const Technologies = lazy(() => import("@/Pages/Technologies"));
const News = lazy(() => import("@/Pages/News"));
const ContactCenter = lazy(() => import("@/Pages/ContactCenter"));
const SenseProfile = lazy(() => import("@/Pages/SenseProfile"));
const Speechllect = lazy(() => import("@/Pages/Speechllect"));
const SignIn = lazy(() => import("@/Pages/SignIn"));
const Confirm = lazy(() => import("@/Pages/Confirm"));
const SignUp = lazy(() => import("@/Pages/SignUp"));
const ForgotPassword = lazy(() => import("@/Pages/ForgotPassword"));
const ResetPassword = lazy(() => import("@/Pages/ResetPassword"));
// const Profile = lazy(() => import("@/Pages/Profile")); // TODO: implementation after approval
const Dashboard = lazy(() => import("@/Pages/Dashboard"));

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false,
  });
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    state: { isLoading, isAuth },
  } = useUser();

  // RetinaJS
  useEffect(() => {
    window.addEventListener("load", retina(document.querySelectorAll("img")));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      import("./Functions/Utilities").then((module) => {
        module.SetHeaderMenuPos();
        module.setDocumentFullHeight();
      });
    }, 1000);
  }, [location]);

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    dispatch(fetchErrorsList());
    dispatch(getUserInfo());
  }, [dispatch]);

  if (isLoading)
    return (
      <div className="fixed left-2/4 top-2/4 translate-x-2/4 translate-y-2/4">
        <Spinner />
      </div>
    );

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
        {
          <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
            <ScrollToTopButton />
            <AnimatePresence exitBeforeEnter>
              <Suspense fallback={<></>}>
                <Routes>
                  {!isAuth && (
                    <>
                      <Route path="/signin" element={<SignIn />} />
                      <Route path="/signup" element={<SignUp />} />
                      <Route path="/confirm" element={<Confirm />} />
                      <Route
                        path="/forgot-password"
                        element={<ForgotPassword />}
                      />
                      <Route
                        path="/resetpassword"
                        element={<ResetPassword />}
                      />
                    </>
                  )}

                  <Route index element={<Home />} />
                  <Route path="/mission" element={<Mission />} />
                  <Route path="/services" element={<Services />} />
                  <Route path="/contacts" element={<Contacts />} />
                  <Route path="/technologies" element={<Technologies />} />
                  <Route path="/news" element={<News />} />
                  <Route path="/contact-center" element={<ContactCenter />} />
                  <Route path="/sense-profile" element={<SenseProfile />} />
                  <Route path="/speechintellect" element={<Speechllect />} />

                  <Route element={<PrivateRoute />}>
                    {/* <Route path="/profile" element={<Profile />} /> */}
                    <Route path="/dashboard/*" element={<Dashboard />} />
                  </Route>

                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Suspense>
            </AnimatePresence>
          </main>
        }
      </div>
    </GlobalContext.Provider>
  );
}

export default App;
