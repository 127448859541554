export const OnlySymbolRegExp = /^[а-яёa-z-]+$/i;
export const FIORegExp = /^[а-яёa-z-\s]+$/i;

export const PhoneRegExp = /^([+]7|8)[\d]{10}$/;

export const PasswordRegExp =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!”#$%&'()*+,-./:;<=>?@[\]^_`{|}~<>№"\\])[A-Za-z\d!”#$%&'()*+,-./:;<=>?@[\]^_`{|}~№\\"]{6,}$/;
/* TODO: Убрать знак + из регулярки EmailRegExp*/
export const EmailRegExp = /^[a-zA-Z0-9.\-_+]+@[a-zA-Z0-9.\-_]+\.[a-z]{2,4}$/gm;

export const cyrillicRegExp = /[а-яё]/gi;

export const OnlySymbolInputRegExp = /^[а-яёa-z-]{0,30}$/i;
export const FIOInputRegExp = /^[а-яёa-z-\s]{0,30}$/i;
export const PhoneInputRegExp = /^[+]?[\d]{0,11}$/;
/* TODO: Убрать знак + из регулярки EmailInputRegExp*/
export const EmailInputRegExp = /^[a-z\-_.@\d+]{0,100}$/i;
export const INNInputRegexp = /^(\d{0,12})$/;
export const OGRNInputRegexp = /^(\d{0,13})$/;

export const INNRegexp = /^(\d{10,12})$/;
export const OGRNRegexp = /^(\d{13})$/;
export const companyEmailValidation =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
