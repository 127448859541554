import { api } from "./api";

/**
 * Register new user
 * @param {Object} data
 * @param {string} data.first_name
 * @param {string} data.last_name
 * @param {number} data.phone
 * @param {string} data.email
 * @param {string} data.password
 * @returns {Promise<AxiosResponse<IEmptyResponse | IErrorRequest, any>>}
 */
export const personalSignUp = (data) => {
  return api.post("/auth/signup/individual", data);
};

export const companySignUp = (data) => {
  return api.post("/auth/signup/legal", data);
};

/**
 * Password recovery user
 * @param {Object} data
 * @param {string} data.email
 * @returns {Promise<AxiosResponse<IEmptyResponse | IErrorRequest, any>>}

 */
export const forgotPassword = (data) => {
  return api.post("/auth/send-recover", data);
};

/**
 * Login user
 * @param {Object} data
 * @param {string} data.email
 * @param {string} data.password
 * @param {string} data.remember_me
 * @returns {Promise<AxiosResponse<IEmptyResponse | IErrorRequest, any>>}
 */
export const signIn = (data) => {
  return api.post("/auth/signin", data);
};

/**
 * Send a message with confirmation link to user's email again
 * @param {string} email
 * @returns {Promise<AxiosResponse<IEmptyResponse | IErrorRequest, any>>}
 */
export const sendResendConfirmEmail = (email) => {
  return api.post("/auth/resend-confirm", { email });
};

// /**
//  * confirm user email for registration
//  * @param string email
//  * @param string code
//  * @returns {Promise<AxiosResponse<IEmptyResponse | IErrorRequest, any>>}
//  */
export const confirmEmail = (email, code) => {
  return api.post("/auth/confirm", { email, code });
};

/**
 * Account Information
 * @returns {Promise<AxiosResponse<IUserAPI, any>>}
 */
export const getAccountInfo = () => {
  return api.get("/user/account");
};

/**
 * Log out of your account
 * @returns {Promise<AxiosResponse<IEmptyResponse | IErrorRequest, any>>}
 */
export const logOut = () => {
  return api.post("/auth/signout");
};

/**
 * Confirm a password changing by email and recovery code
 * @param {Object} data
 * @param {string} data.new_password
 * @param {string} data.email
 * @param {string} data.code
 * @returns {Promise<AxiosResponse<IEmptyResponse | IErrorRequest, any>>}
 */
export const recoverPassword = (data) => {
  return api.post("/auth/confirm-recover", data);
};

/**
 * Support
 * @param {Object} data
 * @param {string} data.company_name
 * @param {string} data.email
 * @param {string} data.full_name
 * @param {string} data.text
 * @param {string} data.title
 * @param {string} data.user_type
 * @returns {Promise<AxiosResponse<IEmptyResponse | IErrorRequest, any>>}
 */
export const sendToSupport = (data) => {
  return api.post("/support", data);
};
