import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

import { ErrorsApi } from '@/Api'
import { useTranslation } from 'react-i18next'

/**
 * @type {{list: IErrorsAPI}}
*/
const initialState = {
    list: {}
}


export const fetchErrorsList = createAsyncThunk('errors/fetch', async () => {
    const res = await ErrorsApi.getList()
    return res.data
})

const errorsSlice = createSlice({
    name: 'errors',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchErrorsList.fulfilled, (state, action) => {
            state.list = action.payload
        })
    }
})



export const useErrors = () => {
    const { i18n } = useTranslation()
    const {
        language,
        options: { fallbackLng }
    } = i18n

    /**
     * @type {IErrorsAPI}
    */
    const errors = useSelector(state => state.errors.list)

    /**  
     * @param {string} code
     * @returns {string}
    */
    const getErrorByCode = (code) => {
        const error = errors[code]
        if (!error) return null

        const message = error[language] || error[fallbackLng[0]]

        return message || ""
    }


    return { errors, getErrorByCode }
}


export default errorsSlice.reducer