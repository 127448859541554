import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useState } from "react";

import { Checkbox, Input, LabelRequired } from "@/Components/Form/Form";
import Buttons from "@/Components/Button/Buttons";
import { UserApi } from "@/Api";
import { getUserInfo } from "@/Features/User";
import {
  INVALID_REQUEST_CONTENT,
  EMAIL_OR_PASSWORD_IS_WRONG,
  UNKNOWN_SERVER_ERROR,
  useErrors,
  USER_NOT_CONFIRMED_ERROR_CODE,
  USER_NOT_FOUND_ERROR_CODE,
  TOKENS_LIMIT_EXCEEDED,
} from "@/Features/Errors";

/**
 * @param {object} props
 * @param {({email: string, password: string}) => void} props.onError
 */
export const SignInForm = ({ onError }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getErrorByCode } = useErrors();

  const [error, setError] = useState("");

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .trim()
          .required("common:form.validate.required")
          .email("common:form.validate.email"),
        password: Yup.string().trim().required("common:form.validate.required"),
        remember_me: Yup.boolean(),
      })}
      onSubmit={async (values) => {
        try {
          setError("");
          await UserApi.signIn(values);
          dispatch(getUserInfo());
          navigate("/dashboard");
        } catch (e) {
          const errCode = e.response.data?.error?.code;
          const errorMsg = getErrorByCode(errCode);

          switch (errCode) {
            case USER_NOT_FOUND_ERROR_CODE:
              return setError(t("common:error_account_not_found"));
            case INVALID_REQUEST_CONTENT:
            case EMAIL_OR_PASSWORD_IS_WRONG:
              return setError(t("common:invalid_request_content_error"));
            case UNKNOWN_SERVER_ERROR:
              return setError(t("common:internal_server_error"));
            case TOKENS_LIMIT_EXCEEDED:
              return setError(t("common:error_session_limit_exceeded"));
            case USER_NOT_CONFIRMED_ERROR_CODE:
              return onError?.(values);
            default:
              if (errorMsg) {
                return setError(errorMsg);
              }
          }

          setError("common:error_request_server");
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <h1 className="text-xmd font-bold leading-[20px] text-center">
            {t("page.sign_in.title")}
          </h1>

          <section className="flex flex-col gap-3">
            <div>
              <Input
                name="email"
                type="email"
                label={<LabelRequired title={t("common:form.field.email")} />}
                className="py-[13px] px-[15px] text-md leading-[initial] w-full border-[1px] border-solid border-[#dfdfdf]"
              />
            </div>

            <div>
              <Input
                name="password"
                type="password"
                label={<LabelRequired title={t("common:form.field.password")} />}
                className="py-[13px] px-[15px] text-md leading-[initial] w-full border-[1px] border-solid border-[#dfdfdf]"
              />
            </div>

            <Checkbox name="remember_me" className="mr-2" labelClass="w-max mr-auto">
              {t("common:form.field.remember_me")}
            </Checkbox>

            {error && <div className="text-error leading-tight">{t(error)}</div>}

            <Buttons
              ariaLabel="login"
              type="submit"
              className="btn-fill btn-fancy w-full font-medium font-serif rounded-none uppercase mt-4"
              themeColor="#232323"
              color="#fff"
              size="md"
              title={t("common:sign_in")}
              disabled={isSubmitting}
            />
            <div className="flex justify-between gap-3 flex-wrap mt-[20px]">
              <Link
                to="/signup"
                className="text-right text-[12px] btn-link after:bg-primary-main text-base-main hover:text-base-main hover:opacity-5 font-medium font-serif uppercase btn after:h-[2px] md:text-md"
              >
                {t("page.sign_in.register")}
              </Link>
              <Link
                to="/forgot-password"
                className="text-right text-[12px] btn-link after:bg-primary-main text-base-main hover:text-base-main hover:opacity-5 font-medium font-serif uppercase btn after:h-[2px] md:text-md"
              >
                {t("page.sign_in.forgot_your_password")}
              </Link>
            </div>
          </section>
        </Form>
      )}
    </Formik>
  );
};
