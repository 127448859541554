import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { sortUsers, sortPayments } from "./utils";

export const adminApi = createApi({
  reducerPath: "admin",
  baseQuery: fetchBaseQuery({ baseUrl: `/api/admin/` }),
  tagTypes: ["Users", "UserId", "Payments", "Freezes"],
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: ({ tabType: user_type, page, search_field, search_query }) =>
        `users?user_type=${user_type}&search_field=${search_field}&search_query=${search_query}&page=${page}`,
      transformResponse: (res) => {
        return {
          pagination: res.response.pagination,
          users: sortUsers(res.response.users || []),
        };
      },
      providesTags: ["Users"],
    }),
    getUserById: builder.query({
      query: (id) => `users/${id}`,
      transformResponse: (res) => {
        return {
          callcenter: res.response.callcenter,
          senseprofile: res.response.senseprofile,
          speechintellect: res.response.speechintellect,
        };
      },
      providesTags: ["UserId"],
    }),
    getPayments: builder.query({
      query: ({ tabType: payment_type, page, search_field, search_query }) =>
        `payments/${payment_type}?search_field=${search_field}&search_query=${search_query}&page=${page}`,
      transformResponse: (res) => {
        return {
          payments: sortPayments(res.response.payments || []),
          pagination: res.response.pagination,
        };
      },
      providesTags: ["Payments"],
    }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `users/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Users"],
    }),
    toggleFreezeUser: builder.mutation({
      query: ({ id, service, isFrozen }) => {
        const method = isFrozen ? "DELETE" : "POST";
        return {
          url: `users/${id}/freezes`,
          method,
          body: {
            service,
          },
        };
      },
      invalidatesTags: ["Freezes"],
    }),
    confirmBill: builder.mutation({
      query: (id) => ({
        url: `payments/bills/${id}/confirm`,
        method: "POST",
      }),
      invalidatesTags: ["Payments"],
    }),
    cancelBill: builder.mutation({
      query: (id) => ({
        url: `payments/bills/${id}/cancel`,
        method: "POST",
      }),
      invalidatesTags: ["Payments"],
    }),
    getFreezes: builder.query({
      query: (id) => `users/${id}/freezes`,
      transformResponse: (res) => {
        return {
          ...res.response.frozen_services,
        };
      },
      providesTags: ["Freezes"],
    }),
    addSubscription: builder.mutation({
      query: ({ id, data }) => ({
        url: `/users/${id}/subscription`,
        method: "POST",
        body: {
          ...data,
        },
      }),
      invalidatesTags: ["Users", "UserId"],
    }),
    getBillDetails: builder.query({
      query: (id) => {
        return {
          url: `payments/bills/${id}/html`,
          method: "GET",
          responseHandler: "text",
        };
      },
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetPaymentsQuery,
  useGetUserByIdQuery,
  useLazyGetUserByIdQuery,
  useLazyGetUsersQuery,
  useLazyGetPaymentsQuery,
  useDeleteUserMutation,
  useToggleFreezeUserMutation,
  useCancelBillMutation,
  useConfirmBillMutation,
  useGetFreezesQuery,
  useAddSubscriptionMutation,
  useGetBillDetailsQuery,
} = adminApi;
