import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

export const PrivateRoute = ({ redirect = "/signin" }) => {
  const { isAuth } = useSelector((state) => state.user.state);

  if (isAuth) return <Outlet />;

  return <Navigate to={redirect} replace />;
};
