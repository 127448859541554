import { Form, Formik } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import Buttons from "@/Components/Button/Buttons";
import { Input, LabelRequired } from "@/Components/Form/Form";
import { UserApi } from "@/Api";

import { PasswordRegExp } from "../lib/validate";
import {
  EXPIRED_RECOVERY_CODE,
  INVALID_REQUEST_CONTENT,
  UNKNOWN_SERVER_ERROR,
  USER_NOT_FOUND_ERROR_CODE,
  WRONG_RECOVERY_CODE,
} from "@/Features/Errors";

export const ResetPasswordForm = ({ email, code, setError, onSuccess }) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        password: "",
        new_password: "",
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .trim()
          .required("common:form.validate.required")
          .min(6, "common:form.validate.password")
          .matches(PasswordRegExp, "common:form.validate.password"),
        new_password: Yup.string()
          .required("common:form.validate.required")
          .oneOf(
            [Yup.ref("password"), null],
            "common:form.validate.passwordsMatch"
          ),
      })}
      onSubmit={async ({ new_password }) => {
        try {
          await UserApi.recoverPassword({ new_password, code, email });
          onSuccess();
        } catch (e) {
          const errorCode = e.response.data?.error?.code;
          switch (errorCode) {
            case USER_NOT_FOUND_ERROR_CODE:
              return setError(t("common:error_account_not_found")); //
            case INVALID_REQUEST_CONTENT:
              return setError(t("common:invalid_request_content_error"));
            case EXPIRED_RECOVERY_CODE:
              return setError(t("common:error_recovery_link_expired"));
            case WRONG_RECOVERY_CODE:
              return setError(t("common:error_recovery_link_expired"));
            case UNKNOWN_SERVER_ERROR:
              return setError(t("common:internal_server_error"));
            default:
              setError(e.response.data?.error?.message);
          }
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <h1 className="text-xmd font-bold leading-[20px] text-center">
            {t("page.create_new_password.title")}
          </h1>

          <section className="flex flex-col gap-3">
            <div>
              <Input
                name="password"
                type="password"
                label={
                  <LabelRequired title={t("common:form.field.password")} />
                }
                className="py-[13px] px-[15px] text-md leading-[initial] w-full border-[1px] border-solid border-[#dfdfdf]"
              />
            </div>

            <div>
              <Input
                name="new_password"
                type="password"
                label={
                  <LabelRequired
                    title={t("page.create_new_password.repeat_password")}
                  />
                }
                className="py-[13px] px-[15px] text-md leading-[initial] w-full border-[1px] border-solid border-[#dfdfdf]"
              />
            </div>

            <Buttons
              ariaLabel="login"
              type="submit"
              className="btn-fill btn-fancy w-full font-medium font-serif rounded-none uppercase mt-4"
              themeColor="#232323"
              color="#fff"
              size="md"
              title={t("common:change_password")}
              disabled={isSubmitting}
            />
            <div className="flex justify-between gap-3 flex-wrap mt-[20px]">
              <Link
                to="/signup"
                className="text-right text-[12px] btn-link after:bg-primary-main text-base-main hover:text-base-main hover:opacity-5 font-medium font-serif uppercase btn after:h-[2px] md:text-md"
              >
                {t("page.sign_in.register")}
              </Link>
              <Link
                to="/signin"
                className="text-right text-[12px] btn-link after:bg-primary-main text-base-main hover:text-base-main hover:opacity-5 font-medium font-serif uppercase btn after:h-[2px] md:text-md"
              >
                {t("page.create_new_password.there_is_an_account")}
              </Link>
            </div>
          </section>
        </Form>
      )}
    </Formik>
  );
};
