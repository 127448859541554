import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";

import { Input, LabelRequired } from "@/Components/Form/Form";
import Buttons from "@/Components/Button/Buttons";
import { UserApi } from "@/Api";
import {
  UNKNOWN_SERVER_ERROR,
  USER_NOT_CONFIRMED_ERROR_CODE,
  USER_NOT_FOUND_ERROR_CODE,
  useErrors,
} from "@/Features/Errors";
import { EmailRegExp, cyrillicRegExp, EmailInputRegExp } from "../lib/validate";

/**
 * @param {object} props
 * @param {{(email: string) => void}} props.onError
 */

export const ForgotPasswordForm = (onError) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getErrorByCode } = useErrors();

  const [error, setError] = useState("");
  return (
    <Formik
      initialValues={{ email: "" }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .trim()
          .required("common:form.validate.required")
          .test("cyrillic", "common:form.validate.notCyrillic", (value) => {
            return !cyrillicRegExp.test(value);
          })
          .matches(EmailRegExp, "common:form.validate.email")
          .email("common:form.validate.email")
          .max(100, "common:form.validate.emailMaxLength"),
      })}
      onSubmit={async (values) => {
        try {
          await UserApi.forgotPassword(values);
          navigate("/forgot-password", { state: values.email });
        } catch (e) {
          const errCode = e.response.data?.error?.code;
          const errorMsg = getErrorByCode(errCode);

          //USER_NOT_FOUND_ERROR_CODE

          switch (errCode) {
            case USER_NOT_FOUND_ERROR_CODE:
              return setError(t("common:error_account_not_found"));
            case USER_NOT_CONFIRMED_ERROR_CODE:
              const state = { state: { email: values.email } };
              navigate("/signIn", state);
              return;
            case UNKNOWN_SERVER_ERROR:
              return setError(t("common:internal_server_error"));
            default:
              if (errorMsg) {
                return setError(errorMsg);
              }
          }

          setError(t("common:error_request_server"));
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <h1 className="text-lg font-bold leading-[20px] text-center">
            {t("page.pass_recovery.title")}
          </h1>

          <h6 className="text-xmd leading-[20px] mb-2">
            {t("page.pass_recovery.first_description")}
          </h6>
          <h6 className="text-xmd leading-[20px]">{t("page.pass_recovery.second_description")}</h6>

          <section className="flex flex-col gap-3">
            <div>
              <Input
                name="email"
                type="text"
                label={<LabelRequired title={t("page.pass_recovery.placeholder_email")} />}
                maxLength="100"
                className="py-[13px] px-[15px] text-md leading-[initial] w-full border-[1px] border-solid border-[#dfdfdf]"
                matchingExpression={EmailInputRegExp}
                onFocus={() => setError("")}
              />
            </div>

            <div className="flex gap-2 items-center mt-3">
              <img
                className="w-[24px] h-[24px]"
                src="/assets/img/exclamation-circle-grey.svg"
                alt=""
              />
              <span className="text-xmd leading-5">{t("page.pass_recovery.warning")}</span>
            </div>

            {error && <div className="text-error leading-tight text-center">{t(error)}</div>}

            <Buttons
              ariaLabel="login"
              type="submit"
              className="btn-fill btn-fancy w-full font-medium font-serif rounded-none uppercase mt-4"
              themeColor="#232323"
              color="#fff"
              size="md"
              title={t("page.pass_recovery.submit_instruction")}
              disabled={isSubmitting}
            />
            <div className="flex justify-between gap-3 flex-wrap mt-[20px]">
              <Link
                to="/signin"
                className="text-right text-[12px] btn-link after:bg-primary-main text-base-main hover:text-base-main hover:opacity-5 font-medium font-serif uppercase btn after:h-[2px] md:text-md"
              >
                {t("page.pass_recovery.there_is_an_account")}
              </Link>
              <Link
                to="/signup"
                className="text-right text-[12px] btn-link after:bg-primary-main text-base-main hover:text-base-main hover:opacity-5 font-medium font-serif uppercase btn after:h-[2px] md:text-md"
              >
                {t("page.pass_recovery.register")}
              </Link>
            </div>
          </section>
        </Form>
      )}
    </Formik>
  );
};
