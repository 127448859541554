export const USER_NOT_FOUND_ERROR_CODE = 2544323027;
export const USER_NOT_CONFIRMED_ERROR_CODE = 3919226735;

export const EMAIL_IS_BUSY_BUT_NOT_CONFIRMED = 3114960921;
export const EMAIL_IS_BUSY_AND_CONFIRMED = 2829825526;
export const UNKNOWN_SERVER_ERROR = 0;

export const USER_ALREADY_CONFIRMED = 4262299518;
export const CONFIRMATION_CODE_EXPIRED = 2836558388;
export const WRONG_CONFIRMATION_CODE = 2808243944;

export const INVALID_REQUEST_CONTENT = 1447728996;
export const EMAIL_OR_PASSWORD_IS_WRONG = 722686330;

export const EXPIRED_RECOVERY_CODE = 4163811866;
export const WRONG_RECOVERY_CODE = 513315846;

export const TOKENS_LIMIT_EXCEEDED = 3008464261;
