import { OverlayTrigger, Tooltip } from "react-bootstrap";

function CustomTooltip({ tooltipInner, placement, className, children }) {
  const renderTooltip = (props) => (
    <Tooltip className={className} {...props}>
      {tooltipInner}
    </Tooltip>
  );

  return (
    <OverlayTrigger placement={placement} overlay={renderTooltip}>
      {children}
    </OverlayTrigger>
  );
}

export default CustomTooltip;
