export const defaultActions = [
  {
    image: "/assets/img/square-list.svg",
    title: "admin_panel.menu_actions.bill_details",
    alt: "List",
    modalType: "billDetails",
  },
  {
    icon: "icon-question",
    title: "admin_panel.menu_actions.user_info",
    navigate: "/dashboard/users/individual_person",
  },
];

export const paymentStatuses = {
  created: {
    sortPriority: 5,
    cellData: {
      iconClass: "far fa-clock text-black opacity-[0.35]",
      textClass: "opacity-50",
      text: "admin_panel.payment_statuses.created",
    },
    actions: defaultActions,
    billActions: [
      {
        icon: "icon-check text-confirm",
        title: "admin_panel.menu_actions.confirm_bill",
        className: "text-confirm",
        modalType: "confirmationBill",
      },
    ],
  },
  user_marked_paid: {
    sortPriority: 1,
    cellData: {
      iconClass: "icon-question opacity-50",
      textClass: "opacity-50",
      text: "admin_panel.payment_statuses.user-marked-paid",
    },
    actions: defaultActions,
    billActions: [
      {
        icon: "icon-check text-confirm",
        title: "admin_panel.menu_actions.confirm_bill",
        className: "text-confirm",
        modalType: "confirmationBill",
      },
      {
        icon: "ti-close text-primary-admin",
        title: "admin_panel.menu_actions.reject_bill",
        className: "text-primary-admin",
        modalType: "rejectionBill",
      },
    ],
  },
  confirmed: {
    sortPriority: 6,
    cellData: {
      iconClass: "icon-check text-confirm",
      textClass: "opacity-[0.85]",
      text: "",
    },
    actions: defaultActions,
    billActions: [],
  },
  cancelled: {
    sortPriority: 2,
    cellData: {
      iconClass: "icon-close text-primary-admin",
      textClass: "text-primary-admin",
      text: "admin_panel.payment_statuses.cancelled",
    },
    actions: defaultActions,
    billActions: [],
  },
  changed_payment_method: {
    sortPriority: 4,
    cellData: {
      iconClass: "icon-close",
      textClass: "",
      text: "admin_panel.payment_statuses.changed-payment-method",
    },
    actions: [
      ...defaultActions,
      {
        icon: "feather-arrow-right",
        title: "admin_panel.menu_actions.go_to_card_payments",
        navigate: "/dashboard/payments/yookassa",
      },
    ],
    billActions: [],
  },
  deleted: {
    sortPriority: 3,
    cellData: {
      iconClass: "icon-close text-primary-admin",
      textClass: "text-primary-admin",
      text: "admin_panel.payment_statuses.deleted",
    },
    actions: defaultActions,
    billActions: [],
  },
};
