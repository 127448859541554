import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const userApi = createApi({
  reducerPath: "userBills",
  baseQuery: fetchBaseQuery({ baseUrl: "/api/user/payments" }),
  tagTypes: ["Bills"],
  endpoints: (builder) => ({
    getBills: builder.query({
      query: ({ service, statuses, page }) =>
        `/bills?service=${service}&statuses=${statuses.join(",")}&page=${page}`,
      transformResponse: (res) => {
        return {
          payments: res.response.bills || [],
          pagination: res.response.pagination,
        };
      },
      providesTags: ["Bills"],
    }),
    getYookassa: builder.query({
      query: ({ service, page }) => `/yookassa?service=${service}&page=${page}`,
      transformResponse: (res) => {
        return {
          payments: res.response.payments || [],
          pagination: res.response.pagination,
        };
      },
    }),
    getSubscriptions: builder.query({
      query: ({ service }) => `/subscriptions?service=${service}`,
      transformResponse: (res) => {
        return {
          ...(res.response || {}),
        };
      },
    }),
    getBillPdf: builder.query({
      queryFn: async ({ id }, api, extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: `/bills/${id}/pdf`,
          responseHandler: (response) => response.blob(),
        });
        const hiddenElement = document.createElement("a");
        const url = window.URL || window.webkitURL;
        const blobPDF = url.createObjectURL(result.data);
        hiddenElement.href = blobPDF;
        hiddenElement.target = "_blank";
        hiddenElement.download = `${id}_bill.pdf`;
        hiddenElement.click();
        return { data: null };
      },
    }),
    getBillHtml: builder.query({
      query: ({ id }) => ({
        url: `/bills/${id}/html`,
        responseHandler: "text",
      }),
    }),
    deleteBill: builder.mutation({
      query: ({ id }) => ({
        url: `/bills/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Bills"],
    }),
    sendMail: builder.mutation({
      query: ({ id, email }) => ({
        url: `/bills/${id}/send-email`,
        method: "POST",
        body: {
          email,
        },
      }),
    }),
    needConfirm: builder.mutation({
      query: ({ id }) => ({
        url: `/bills/${id}/need-confirm`,
        method: "POST",
      }),
      invalidatesTags: ["Bills"],
    }),
    changePayment: builder.mutation({
      queryFn: async ({ id, paymentMethod }, api, extraOptions, baseQuery) => {
        const { data } = await baseQuery({
          url: `/bills/${id}/change-payment`,
          method: "POST",
          body: {
            payment_method: paymentMethod,
          },
        });
        var hiddenElement = document.createElement("a");
        hiddenElement.href = data.response.payment_link;
        hiddenElement.target = "_blank";
        hiddenElement.click();
        return { data: null };
      },
      invalidatesTags: ["Bills"],
    }),
  }),
});

export const {
  useGetBillsQuery,
  useLazyGetBillsQuery,
  useGetSubscriptionsQuery,
  useLazyGetYookassaQuery,
  useLazyGetBillPdfQuery,
  useLazyGetBillHtmlQuery,
  useGetBillHtmlQuery,
  useDeleteBillMutation,
  useSendMailMutation,
  useNeedConfirmMutation,
  useChangePaymentMutation,
} = userApi;
