import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

import { UserApi } from '@/Api'

const initialState = {
    state: { isLoading: true, isAuth: false },
    info: {
        first_name: '',
        last_name: '',
        phone: '',
        email: ''
    }
}

export const getUserInfo = createAsyncThunk('user/info', async () => {
    const res = await UserApi.getAccountInfo()

    return res.data.response
})

export const fetchLogOut = createAsyncThunk('user/logOut', async () => {
    const res = await UserApi.logOut()

    return res.data
})

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        logOut: (state) => {
            state.info = { ...initialState.info }
            state.state.isAuth = false
        }
    },
    extraReducers: builder => {
        builder.addCase(getUserInfo.pending, (state) => {
            state.state.isLoading = true
        })
        builder.addCase(getUserInfo.fulfilled, (state, action) => {
            state.info = action.payload

            state.state = {
                isAuth: true,
                isLoading: false
            }
        })
        builder.addCase(getUserInfo.rejected, (state) => {
            state.state.isLoading = false
            state.state.isAuth = false
        })

        builder.addCase(fetchLogOut.fulfilled, (state) => {
            state.info = { ...initialState.info }
            state.state.isAuth = false
        })
    }
})

export const { logOut } = userSlice.actions

/** 
 * @returns {typeof initialState}
 */
export const useUser = () => useSelector(state => state.user)

export default userSlice.reducer