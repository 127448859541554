import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const ordersApi = createApi({
  reducerPath: 'orders',
  baseQuery: fetchBaseQuery({baseUrl: '/api/orders'}),
  endpoints: (builder) => ({
    getOrderById: builder.query({
      query: ({id}) => `/${id}`,
      transformResponse: (res) => ({
        ...(res.response || {})
      })
    }),
    payForOrderBill: builder.mutation({
      query: ({id}) => ({
        url: `${id}/bill`,
        method: "POST"
      }),
      transformResponse: (res) => ({
        billId: res.response.id
      })
    }),
    payForOrderYookassa: builder.mutation({
      queryFn: async ({ id }, api, extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: `/${id}/yookassa`,
          method: "POST",
        })
        const hiddenElement = document.createElement('a');
        hiddenElement.href = result.data.response.payment_link;
        // hiddenElement.target = '_blank';
        hiddenElement.click();
        return { data: null }
      }
    })
  })
})

export const {useLazyGetOrderByIdQuery, usePayForOrderBillMutation, usePayForOrderYookassaMutation} = ordersApi