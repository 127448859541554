import { format, parseISO } from "date-fns";
import { paymentStatuses } from "../paymentStatuses";

export function ISOStringToDateString(date) {
  if (!date) return;

  return format(parseISO(date), "dd.MM.yyyy");
}

export function sortUsers(users) {
  const arrayForSort = [...users];

  const sortedArray = arrayForSort.sort(
    (a, b) =>
      b.has_debt - a.has_debt || getComparableString(a).localeCompare(getComparableString(b))
  );
  return sortedArray;
}

export function getFullName(user) {
  return `${user.last_name} ${user.first_name} ${user.middle_name || ""}`;
}

function getComparableString(user) {
  const fullName = getFullName(user);

  return user.company_name?.toLowerCase() || fullName.toLowerCase();
}

export function sortPayments(payments) {
  const arrayForSort = [...payments];

  const sortPriorities = Object.fromEntries(
    Object.entries(paymentStatuses).map(([key, value]) => [key, value.sortPriority])
  );

  const sortedPayments = arrayForSort.sort(
    (a, b) =>
      sortPriorities[a.status] - sortPriorities[b.status] ||
      new Date(b.date || b.date_sended) - new Date(a.date || a.date_sended)
  );

  return sortedPayments;
}

export function megabyteToSize(megabyte) {
  const sizes = ["MB", "GB", "TB"];
  if (megabyte === 0) return "0 MB";
  const i = parseInt(Math.floor(Math.log(Math.abs(megabyte)) / Math.log(1024)), 10);
  if (i === 0) return `${megabyte} ${sizes[i]}`;
  return `${(megabyte / 1024 ** i).toFixed(2)} ${sizes[i]}`;
}

export function formatCost(cost) {
  if (!cost) return;

  const er = /^-?[0-9]+$/;
  const spacedCost = Number(cost).toLocaleString("ru");

  if (er.test(cost)) return `${spacedCost},00`;

  return spacedCost;
}
