import { configureStore } from "@reduxjs/toolkit";

import userReducer from "@/Features/User/userSlice";
import { authMiddleware } from "@/Features/User/authMiddleware";
import errorsReducer from "@/Features/Errors/errorsSlice";
import { adminApi } from "@/Features/Dashboard/AdminPanel/adminApi";
import { setupListeners } from "@reduxjs/toolkit/query";
import { userApi } from "@/Features/Dashboard/UserPanel/userApi";
import { ordersApi } from "@/Features/Dashboard/UserPanel/orderApi";

export const store = configureStore({
  reducer: {
    user: userReducer,
    errors: errorsReducer,
    [adminApi.reducerPath]: adminApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(authMiddleware, adminApi.middleware, userApi.middleware, ordersApi.middleware),
});

setupListeners(store.dispatch);

export default store;
