import React from "react";
import { useTranslation } from "react-i18next";

import Buttons from "@/Components/Button/Buttons";
import { useTimer } from "@/Functions/UseTimer";

const ONE_MINUTE = 60;
const formatTime = (time) => time.toString().padStart(2, "0");

export default function IntervalSubmit({ onCancel, onSubmit }) {
  const { t } = useTranslation();
  const interval = useTimer({});
  const minutes = formatTime(Math.floor(interval.secondsLeft / ONE_MINUTE));
  const seconds = formatTime(interval.secondsLeft - minutes * ONE_MINUTE);

  const handleSubmit = () => {
    onSubmit();
    interval.resetTimer();
  };
  return (
    <>
      <Buttons
        onClick={onCancel}
        title={t("common:sign_in")}
        ariaLabel="login"
        type="submit"
        className="btn-fill btn-fancy w-full font-medium font-serif rounded-none uppercase mt-4"
        themeColor="#232323"
        color="#fff"
        size="md"
      />

      <div className="text-secondary text-center mt-4">
        <div>{t("page.confirm_email.no_receive_an_email")}</div>
        <div>
          {interval.secondsLeft > 0 ? (
            <p>
              {t("common:interval_submit")}: {minutes}:{seconds}
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="flex gap-2 items-center justify-center">
          <span>{t("page.confirm_email.check_spam")}</span>
          <span>{t("common:or")}</span>
          <Buttons
            type="submit"
            disabled={interval.secondsLeft > 0}
            className="p-0 !text-base-main !text-md border-t-0 rounded-none border-l-0 border-r-0 !bg-transparent border-b border-solid border-b-primary-main !leading-6 !outline-none active:!border-transparent focus:!border-transparent"
            size="sm"
            title={t("page.confirm_email.resend_email")}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </>
  );
}
