import { useState, useEffect, useRef, useCallback } from "react";
import { differenceInSeconds } from "date-fns";

export const useTimer = ({ key = "interval", intervalInSeconds = 60 }) => {
  const [secondsLeft, setSecondsLeft] = useState(() => {
    const timerLocalStorage = localStorage.getItem(key);
    const nowTimestamp = Date.now();

    if (!timerLocalStorage) {
      localStorage.setItem(key, nowTimestamp);
      return intervalInSeconds;
    }

    const timestampStart = Number(timerLocalStorage);
    const timeStart = isNaN(timestampStart) ? 0 : timestampStart;
    const diff = Math.abs(differenceInSeconds(timeStart, nowTimestamp));

    if (diff > intervalInSeconds) {
      localStorage.setItem(key, nowTimestamp);
      return intervalInSeconds;
    }

    return intervalInSeconds - diff;
  });

  const timerRef = useRef();

  const runTimer = useCallback(() => {
    timerRef.current = window.setInterval(() => {
      setSecondsLeft((prev) => {
        const newSecondsLeft = prev - 1;
        if (!newSecondsLeft) {
          window.clearInterval(timerRef.current);
        }
        return newSecondsLeft;
      });
    }, 1000);
  }, []);

  const resetTimer = useCallback(() => {
    setSecondsLeft(intervalInSeconds);
    localStorage.setItem(key, Date.now());
    runTimer();
  }, [intervalInSeconds, key, runTimer]);

  useEffect(() => {
    runTimer();

    return () => {
      window.clearInterval(timerRef.current);
    };
  }, [runTimer]);

  return { secondsLeft, resetTimer };
};
