import { Trans, useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { UserApi } from "@/Api";
import { useErrors } from "@/Features/Errors";
import IntervalSubmit from "./IntervalSubmit";

export const ConfirmEmail = ({ email = "", onCancel }) => {
  const { t } = useTranslation();
  const { getErrorByCode } = useErrors();

  const onResendConfirm = async () => {
    try {
      await UserApi.sendResendConfirmEmail(email);
      toast.success(t("page.confirm_email.success"));
    } catch (e) {
      const errorMsg = getErrorByCode(e.response.data?.error?.code);

      if (errorMsg) {
        return toast.error(errorMsg);
      }

      toast.error(t("page.confirm_email.error"));
    }
  };

  return (
    <>
      <div className="text-center">
        <i className="fa-regular fa-envelope text-[60px]"></i>
      </div>
      <h1 className="text-[40px] m-0">
        <span className="text-primary-main">
          {t("page.confirm_email.title.bold")}
        </span>{" "}
        <span>{t("page.confirm_email.title.other")}</span>
      </h1>

      <div className="my-3 text-secondary leading-6">
        <div>
          <Trans i18nKey="page.confirm_email.description.manual">
            {{ email }}
          </Trans>
        </div>

        <div>{t("page.confirm_email.description.correction")}</div>
      </div>

      <IntervalSubmit onCancel={onCancel} onSubmit={onResendConfirm} />
    </>
  );
};
