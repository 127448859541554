import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useUser } from "../userSlice";

export const ProfileButton = ({ className }) => {
  const {
    state: { isAuth },
  } = useUser();
  const { t } = useTranslation();

  return (
    <div className={clsx("inline-block text-base-main", className)}>
      {!isAuth && (
        <Link to="/signin" title={t("common:sign_in")}>
          <img
            className="w-[24px]"
            src="./assets/img/login.png"
            alt="login ico"
          />
        </Link>
      )}
      {isAuth && (
        <Link to="/dashboard" title={t("common:profile")}>
          <i className="fa-solid fa-user" />
        </Link>
      )}
    </div>
  );
};
