/**
 * Product Versions Information
 */
export const getProductVersions = () => {
  const versions = {
    callCenter: "v.00.00",
    senseProfile: "v.00.00",
    speechllect: "v.00.00",
  };

  return new Promise((res) => {
    setTimeout(() => {
      res(versions);
    }, 2000);
  });
};
