import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

// Libraries
import { BrowserRouter } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import { LazyMotion, domMax } from "framer-motion";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";

// css
import 'react-toastify/dist/ReactToastify.css';
import "./Assets/css/icons.css"
import "./Assets/css/global.css"
import "./Assets/css/pages.css"
import "./index.scss"

// localizations
import "./i18n"

import { store } from "./Store";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <LazyMotion features={domMax}>
      <ParallaxProvider>
        <BrowserRouter>
          <App />
          <ToastContainer
            className="leading-6"
            position="top-right"
            autoClose={4000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
        </BrowserRouter>
      </ParallaxProvider>
    </LazyMotion>
  </Provider>
);