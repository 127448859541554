import React, { memo, useState } from "react";

// Libraries
import { useField } from "formik";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import CustomTooltip from "@/Features/Dashboard/AdminPanel/ui/CustomTooltip";

export const Input = memo(
  ({
    label,
    labelClass,
    className,
    showErrorMsg,
    type = "text",
    matchingExpression,
    showSymbolsCount,
    ...props
  }) => {
    const [field, meta, helpers] = useField(props);
    const [isVisible, setIsVisible] = useState(false);
    const { t } = useTranslation();

    const onToggleVisible = () => {
      setIsVisible((state) => !state);
    };

    return (
      <label
        className={clsx("block relative", labelClass, {
          "errors-danger": meta.touched && meta.error,
        })}
      >
        {label}
        <span className="inline-block w-full relative">
          <input
            className={clsx(className, {
              "errors-danger": meta.touched && meta.error,
              "pr-11": type === "password",
            })}
            {...field}
            {...props}
            type={isVisible ? "text" : type}
            onChange={(e) => {
              if (matchingExpression) {
                const target = e.target.value;
                const check = matchingExpression.test(target);
                if (!check) return false;
              }
              helpers.setValue(e.target.value);
            }}
          />
          {type === "password" && (
            <i
              className={clsx("fa-solid absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer", {
                "fa-eye-slash": isVisible,
                "fa-eye ": !isVisible,
              })}
              onClick={onToggleVisible}
            />
          )}
        </span>
        {props.bottomlabel}
        {showSymbolsCount && (
          <p className="text-black opacity-25 text-sm text-end mt-[4px]">
            {field.value.length} / {props.maxLength}
          </p>
        )}
        {meta.touched && meta.error && showErrorMsg && (
          <span
            className="text-sm text-error block mt-[5px]"
            dangerouslySetInnerHTML={{ __html: t(meta.error) }}
          />
        )}
      </label>
    );
  }
);

export const TextArea = memo(
  ({ label, labelClass, className, showErrorMsg, showSymbolsCount, ...props }) => {
    const [field, meta] = useField(props);
    const { t } = useTranslation();

    return (
      <label
        className={clsx("block relative", labelClass, {
          "errors-danger": meta.touched && meta.error,
        })}
      >
        {label}
        <textarea
          className={clsx(className, {
            "errors-danger": meta.touched && meta.error,
          })}
          {...field}
          {...props}
        />
        {showSymbolsCount && (
          <p className="text-black opacity-25 text-sm text-end mt-[4px]">
            {field.value.length} / {props.maxLength}
          </p>
        )}
        {meta.touched && meta.error && showErrorMsg && (
          <span
            className="text-sm text-error block mt-[5px]"
            dangerouslySetInnerHTML={{ __html: t(meta.error) }}
          />
        )}
      </label>
    );
  }
);

export const Checkbox = memo(({ label, labelClass, className, children, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <label
      className={clsx("items-center relative", labelClass, {
        "errors-danger": meta.touched && meta.error,
        flex: props.isFlexDisplay,
      })}
    >
      <input
        type="checkbox"
        className={clsx(className, {
          "errors-danger": meta.touched && meta.error,
        })}
        {...field}
        {...props}
      />
      {children}
    </label>
  );
});

export const LabelRequired = ({ title, className }) => {
  return (
    <span className={className}>
      {title} <span className="text-error">*</span>
    </span>
  );
};

export const Label = ({ title, required, className }) => {
  return (
    <span className={className}>
      {title} {required && <span className="text-error">*</span>}
    </span>
  );
};

export function LabelWithTooltip(props) {
  const { t } = useTranslation();
  return (
    <div className={clsx("flex items-center gap-[4px]", props.className)}>
      <Label title={props.title} className={props.labelClass} />
      {props.icon && (
        <CustomTooltip placement="bottom" className="small" tooltipInner={t(props.tooltipText)}>
          {<i className={`${props.icon}`} />}
        </CustomTooltip>
      )}
    </div>
  );
}

Checkbox.defaultProps = {
  isFlexDisplay: true,
};

Input.defaultProps = {
  showErrorMsg: true,
  showSymbolsCount: false,
};
